import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import LinkedHeading from '../../components/LinkedHeading';
import ReactPlayground from '../../components/ReactPlayground';
import CardBasic from '../../examples/Card/Basic';
import CardBgColor from '../../examples/Card/BgColor';
import CardBodyOnly from '../../examples/Card/BodyOnly';
import CardBodyShorthand from '../../examples/Card/BodyShorthand';
import CardBorder from '../../examples/Card/Border';
import CardColumns from '../../examples/Card/Columns';
import CardDeck from '../../examples/Card/Deck';
import CardGroup from '../../examples/Card/Group';
import CardHeaderAndFooter from '../../examples/Card/HeaderAndFooter';
import CardImageAndText from '../../examples/Card/ImageAndText';
import CardImgOverlay from '../../examples/Card/ImgOverlay';
import CardKitchenSink from '../../examples/Card/KitchenSink';
import CardListGroups from '../../examples/Card/ListGroups';
import CardListGroupWithHeader from '../../examples/Card/ListGroupWithHeader';
import CardNavPills from '../../examples/Card/NavPills';
import CardNavTabs from '../../examples/Card/NavTabs';
import CardText from '../../examples/Card/Text';
import CardWithHeader from '../../examples/Card/WithHeader';
import CardWithHeaderAndQuote from '../../examples/Card/WithHeaderAndQuote';
import CardWithHeaderStyled from '../../examples/Card/WithHeaderStyled';
export const query = graphql`
  query CardQuery {
    Card: componentMetadata(displayName: { eq: "Card" }) {
      ...ComponentApi_metadata
    }
    CardBody: componentMetadata(displayName: { eq: "CardBody" }) {
      ...ComponentApi_metadata
    }
    CardImg: componentMetadata(displayName: { eq: "CardImg" }) {
      ...ComponentApi_metadata
    }
    CardImgOverlay: componentMetadata(displayName: { eq: "CardImgOverlay" }) {
      ...ComponentApi_metadata
    }
    CardDeck: componentMetadata(displayName: { eq: "CardDeck" }) {
      ...ComponentApi_metadata
    }
    CardGroup: componentMetadata(displayName: { eq: "CardGroup" }) {
      ...ComponentApi_metadata
    }
    CardColumns: componentMetadata(displayName: { eq: "CardColumns" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "cards"
    }}>{`Cards`}</h1>
    <p className="lead">
  Bootstrap’s cards provide a flexible and extensible content container
  with multiple variants and options.
    </p>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h2>
    <ReactPlayground codeText={CardBasic} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "content-types"
    }}>{`Content types`}</h2>
    <h3 {...{
      "id": "body"
    }}>{`Body`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`<Card.Body>`}</inlineCode>{` to pad content inside a `}<inlineCode parentName="p">{`<Card>`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={CardBodyOnly} mdxType="ReactPlayground" />
    <p>{`Alternatively, you can use this shorthand version for Cards with body
only, and no other children`}</p>
    <ReactPlayground codeText={CardBodyShorthand} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "title-text-and-links"
    }}>{`Title, text, and links`}</h3>
    <p>{`Using `}<inlineCode parentName="p">{`<Card.Title>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<Card.Subtitle>`}</inlineCode>{`, and
`}<inlineCode parentName="p">{`<Card.Text>`}</inlineCode>{` inside the `}<inlineCode parentName="p">{`<Card.Body>`}</inlineCode>{` will line them up nicely.
`}<inlineCode parentName="p">{`<Card.Link>`}</inlineCode>{`s are used to line up links next to each other.`}</p>
    <p><inlineCode parentName="p">{`<Card.Text>`}</inlineCode>{` outputs `}<inlineCode parentName="p">{`<p>`}</inlineCode>{` tags around the content, so you can
use multiple `}<inlineCode parentName="p">{`<Card.Text>`}</inlineCode>{`s to create separate paragraphs.`}</p>
    <ReactPlayground codeText={CardText} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "list-groups"
    }}>{`List Groups`}</h3>
    <p>{`Create lists of content in a card with a flush list group.`}</p>
    <ReactPlayground codeText={CardListGroups} mdxType="ReactPlayground" />
    <ReactPlayground codeText={CardListGroupWithHeader} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "kitchen-sink"
    }}>{`Kitchen Sink`}</h3>
    <ReactPlayground codeText={CardKitchenSink} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "header-and-footer"
    }}>{`Header and Footer`}</h3>
    <p>{`You may add a header by adding a `}<inlineCode parentName="p">{`<Card.Header>`}</inlineCode>{` component.`}</p>
    <ReactPlayground codeText={CardWithHeader} mdxType="ReactPlayground" />
    <p>{`A `}<inlineCode parentName="p">{`<CardHeader>`}</inlineCode>{` can be styled by passing a heading element
through the `}<inlineCode parentName="p">{`<as>`}</inlineCode>{` prop`}</p>
    <ReactPlayground codeText={CardWithHeaderStyled} mdxType="ReactPlayground" />
    <ReactPlayground codeText={CardWithHeaderAndQuote} mdxType="ReactPlayground" />
    <ReactPlayground codeText={CardHeaderAndFooter} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "images"
    }}>{`Images`}</h2>
    <p>{`Cards include a few options for working with images. Choose from
appending “image caps” at either end of a card, overlaying images with
card content, or simply embedding the image in a card.`}</p>
    <h3 {...{
      "id": "image-caps"
    }}>{`Image caps`}</h3>
    <p>{`Similar to headers and footers, cards can include top and bottom “image
caps”—images at the top or bottom of a card.`}</p>
    <ReactPlayground codeText={CardImageAndText} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "image-overlays"
    }}>{`Image Overlays`}</h3>
    <p>{`Turn an image into a card background and overlay your card’s text.
Depending on the image, you may or may not need additional styles or
utilities.`}</p>
    <ReactPlayground codeText={CardImgOverlay} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "navigation"
    }}>{`Navigation`}</h2>
    <p>{`Add some navigation to a card’s header (or block) with React Bootstrap’s
`}<a parentName="p" {...{
        "href": "../navs"
      }}>{`Nav`}</a>{` components.`}</p>
    <ReactPlayground codeText={CardNavTabs} mdxType="ReactPlayground" />
    <ReactPlayground codeText={CardNavPills} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "card-styles"
    }}>{`Card Styles`}</h2>
    <h3 {...{
      "id": "background-color"
    }}>{`Background Color`}</h3>
    <p>{`You can change a card's appearance by changing their `}<inlineCode parentName="p">{`<bg>`}</inlineCode>{`, and `}<inlineCode parentName="p">{`<text>`}</inlineCode>{` props.`}</p>
    <ReactPlayground codeText={CardBgColor} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "border-color"
    }}>{`Border Color`}</h3>
    <ReactPlayground codeText={CardBorder} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "card-layout"
    }}>{`Card layout`}</h2>
    <h3 {...{
      "id": "card-groups"
    }}>{`Card Groups`}</h3>
    <ReactPlayground codeText={CardGroup} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "card-deck"
    }}>{`Card Deck`}</h3>
    <ReactPlayground codeText={CardDeck} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "card-columns"
    }}>{`Card Columns`}</h3>
    <ReactPlayground codeText={CardColumns} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Card} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.CardBody} exportedBy={props.data.Card} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.CardImg} exportedBy={props.data.Card} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.CardImgOverlay} exportedBy={props.data.Card} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.CardDeck} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.CardGroup} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.CardColumns} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      